<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Редактирование заказа"
        update
        url-update="/order/update/:id"
        url-back="/order"
        action-one="order/one"
    >
        <order-form :model="model" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import OrderForm from '@/components/forms/OrderForm.vue'
export default {
    name: 'OrderUpdate',
    components: { FormView, OrderForm }
}
</script>