<template>
    <v-form>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-row v-if="!editable" justify="start" align="center">
            <v-col class="col-auto">
                <div class="text-caption">Статус заказа: <span class="font-weight-medium" :class="[(status === 'complete' ? 'success' : 'warning') + '--text']">{{ _.get(model, 'statusLabel', '') }}</span></div>
                <br />
            </v-col >
        </v-row>

        <!-- Order main form -->
        <v-text-field 
            v-model="form.customer"
            :readonly="!editable"
            :error-messages="getErrors('form.customer')"
            label="Конечник" 
            name="customer" 
            type="text" 
            placeholder=" "
            autofocus="autofocus" 
            color="light-green" 
        ></v-text-field>

        <v-text-field 
            v-model="$v.form.inn.$model"
            :readonly="!editable"
            :error-messages="getErrors('form.inn', {
                pattern: 'Неверный ИНН'
            })"
            label="ИНН" 
            name="inn" 
            type="text" 
            placeholder=" "
            color="light-green" 
        ></v-text-field>

        <v-text-field 
            v-model="$v.form.name.$model"
            :readonly="!editable"
            :error-messages="getErrors('form.name')"
            label="Название" 
            name="name" 
            type="text" 
            placeholder=" "
            color="light-green" 
        ></v-text-field>

        <v-text-field 
            v-model="form.address"
            :readonly="!editable"
            :error-messages="getErrors('form.address')"
            label="Адрес" 
            name="address" 
            type="text" 
            placeholder=" "
            color="light-green" 
        ></v-text-field>

        <v-text-field 
            v-model="$v.form.email.$model"
            :readonly="!editable"
            :error-messages="getErrors('form.email', {
                custom: 'Неверный E-mail'
            })"
            label="E-mail" 
            name="email" 
            type="text" 
            placeholder=" "
            color="light-green" 
        ></v-text-field>

        <v-text-field 
            v-model="form.contacts"
            :readonly="!editable"
            :error-messages="getErrors('form.contacts')"
            label="Контактное лицо" 
            name="contacts" 
            type="text" 
            placeholder=" "
            color="light-green" 
        ></v-text-field>

        <v-select
          v-model="$v.form.partner_id.$model"
          :readonly="!editable"
          :error-messages="getErrors('form.partner_id')"
          required
          :items="partners.items"
          :loading="partners.loading"
          label="Партнер"
        ></v-select>

        <v-textarea
            v-model="form.comment"
            :readonly="!editable"
            :error-messages="getErrors('form.comment')"
            label="Комментарий" 
            name="comment" 
            color="light-green" 
            :clearable="editable"
            clear-icon="mdi-close-circle"
            rows="5"
            no-resize
        ></v-textarea>
        <!-- END Order main form -->

        <v-divider class="my-4" />

        <!-- Positions title & actions -->
        <v-row justify="start" align="center">
            <v-col>
                <div class="text-caption">Состав заказа</div>
            </v-col >
            <v-spacer />
            <v-col v-if="editable" class="d-flex flex-row justify-end">
                <v-btn dark small outlined color="light-green" @click.prevent.stop="addPosition">
                    <v-icon left dark>
                        mdi-plus
                    </v-icon>
                    Добавить товарную позицию
                </v-btn>
            </v-col>
        </v-row>
        <!-- END Positions title & actions -->

        <!-- Positions form -->
        <v-row v-if="_.isNil(status) || status === 'ready'" justify="start" align="center">
            <v-col cols="12">
                <v-simple-table v-if="_.size(form.items) > 0">
                    <tbody>
                        <tr v-for="(pos, i) in $v.form.items.$each.$iter" :key="i">
                            <td>
                                <v-select
                                  v-model="pos.license_type_id.$model"
                                  :readonly="!editable"
                                  :error-messages="getErrors(`form.items.$each.$iter.${i}.license_type_id`)"
                                  required
                                  :items="license_types.items"
                                  :loading="license_types.loading"
                                  label="Тип лицензии"
                                ></v-select>
                            </td>
                            <td>
                                <v-text-field 
                                    v-model="pos.quantity.$model"
                                    :readonly="!editable"
                                    :error-messages="getErrors(`form.items.$each.$iter.${i}.quantity`, {
                                        integer: 'Неверное количество',
                                        custom: 'Значение менее 1'
                                    })"
                                    label="Количество ключей" 
                                    name="quantity" 
                                    type="text" 
                                    placeholder=" "
                                    color="light-green" 
                                ></v-text-field>
                            </td>
                            <td v-if="editable" class="text-center">
                                <v-btn dark x-small fab color="red" @click.prevent.stop="removePosition(i)">
                                    <v-icon dark>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <v-alert 
                    v-else 
                    dense
                    outlined
                    color="warning"
                >В состав заказа не добавлено ни одной товарной позиции.</v-alert>
            </v-col>
        </v-row>
        <!-- END Positions form -->

        <!-- Positions view table -->
        <v-row v-else justify="start" align="center">
            <v-col cols="12">
                <v-simple-table v-if="_.size(form.items) > 0">
                    <thead>
                        <tr>
                            <th>Ключ</th>
                            <th>Тип лицензии</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(code, i) in codes" :key="i">
                            <td>
                                <router-link :to="`/code/update/${code.id}`">{{ code.value }}</router-link>
                            </td>
                            <td>
                                <router-link :to="`/license_type/update/${code.license_type_id}`">{{ _.get(code, 'license_type.name') }}</router-link>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <!-- END Positions view table -->

        <v-divider class="my-4" />

        <!-- Actions -->
        <v-row justify="start" align="center">
            <v-col class="col-auto">
                <v-btn dark outlined color="light-green" @click.prevent.stop="back">
                    <v-icon left dark>
                        mdi-arrow-left
                    </v-icon>
                    Назад к списку
                </v-btn>
            </v-col>
            <v-col v-if="action === 'create' || (action === 'update' && (_.isNil(status) || status === 'ready'))" class="col-auto">
                <v-btn dark color="light-green" @click.prevent.stop="save">
                    <v-progress-circular v-if="waiting.save" indeterminate :size="24" color="white" class="mr-3"></v-progress-circular>
                    Сохранить
                </v-btn>
            </v-col>
            <v-col v-if="action === 'update' && (_.isNil(status) || status === 'ready')" class="col-auto">
                <v-btn dark color="light-green" @click.prevent.stop="build">
                    <v-progress-circular v-if="waiting.build" indeterminate :size="24" color="white" class="mr-3"></v-progress-circular>
                    Сохранить и выполнить
                </v-btn>
            </v-col>
            <v-spacer />
            <v-col v-if="status === 'complete'" class="col-auto">
                <v-btn dark color="warning" @click.prevent.stop="cancel(null)">
                    Отменить заказ
                </v-btn>
            </v-col>
            <!-- <v-col v-if="status === 'complete'" class="col-auto">
                <v-btn dark color="error" @click.prevent.stop="remove(null)">
                    Удалить заказ
                </v-btn>
            </v-col> -->
        </v-row>
        <!-- END Actions -->

        <!-- Cancel confirm dialog -->
          <v-dialog v-model="popup.cancel.show" max-width="500px">
            <v-card>
                <v-card-title class="headline">Вы действительно хотите <br />отменить этот заказ?</v-card-title>
                <v-card-text>
                    <v-alert
                      v-if="!_.isNil(popup.cancel.error)"
                      dense
                      type="error"
                    >
                        {{ popup.cancel.error }}
                    </v-alert>

                    <p>После отмены заказ будет переведен в статус "Отменен", а все ключи, созданные в рамках заказа - станут неактивными.</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark color="light-green" @click.prevent.stop="cancel(false)">Отмена</v-btn>
                    <v-btn dark color="light-green" outlined @click="cancel(true)">
                        <v-progress-circular v-if="waiting.popup.cancel" indeterminate :size="24" color="white" class="mr-3"></v-progress-circular>
                        OK
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- END Cancel confirm dialog -->

        <!-- Remove confirm dialog -->
        <!-- <v-dialog v-model="popup.remove.show" max-width="500px">
            <v-card>
                <v-card-title class="headline">Вы действительно хотите <br />удалить этот заказ?</v-card-title>
                <v-card-text>
                    <v-alert
                      v-if="!_.isNil(popup.remove.error)"
                      dense
                      type="error"
                    >
                        {{ popup.remove.error }}
                    </v-alert>
                    <p>После отмены заказ и все ключи, созданные в рамках заказа, будут безвозвратно удалены.</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark color="light-green" @click.prevent.stop="remove(false)">Отмена</v-btn>
                    <v-btn dark color="light-green" outlined @click="remove(true)">
                        <v-progress-circular v-if="waiting.popup.remove" indeterminate :size="24" color="white" class="mr-3"></v-progress-circular>
                        OK
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog> -->
        <!-- END Cancel confirm dialog -->
    </v-form>
</template>
<script>
import DataListMixin from '@/mixins/DataListMixin.vue'
import { errorMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required, minLength, email, integer } from 'vuelidate/lib/validators'

const SCENARIO_SAVE = 'save';
const SCENARIO_BUILD = 'build';

export default {
    name: 'OrderForm',
    props: {
        model: {
            type: Object
        }
    },
    mixins: [errorMixin, DataListMixin, validationMixin],
    data() {
        return {
            scenario: SCENARIO_SAVE,
            popup: {
                cancel: {
                    show: false,
                    error: null
                },
                remove: {
                    show: false,
                    error: null
                }
            },
            partners: {
                loading: true,
                items: []
            },
            license_types: {
                loading: true,
                items: []  
            },
            form: {
                customer: '',
                inn: '',
                name: '',
                address: '',
                email: '',
                contacts: '',
                partner_id: null,
                comment: '',
                items: []
            },
            waiting: {
                save: false,
                build: false,
                popup: {
                    cancel: false,
                    remove: false
                }
            }
        };
    },
    computed: {
        action() {
            return _.get(this, 'model.id', 0) <= 0 ? 'create' : 'update';
        },
        status() {
            return _.get(this, 'model.status', null);
        },
        editable() {
            return  !_.includes(['complete', 'cancel'], this.status);
        },
        codes() {
            return _.flatten(_.map(this.model.items, (item) => {
                return item.codes;
            }));
        }
    },
    validations() {
        const STRING_FIELD_MIN_LENGTH = 2;
        return {
            form: {
                inn: {
                    required,
                    pattern: function(value) {
                        return /^([0-9]{10}|[0-9]{12})$/.test(value);
                    }
                },
                name: {
                    required,
                    minLength: minLength(STRING_FIELD_MIN_LENGTH)
                },
                email: {
                    custom: function(value) {
                        return _.size(value.toString()) > 0 ? email(value) : true;
                    }
                },
                partner_id: {
                    required
                },
                items: {
                    required: function(value) {
                        return this.scenario === 'build' ? required(value) : true;
                    },
                    $each: {
                        license_type_id: {
                            required
                        },
                        quantity: {
                            required,
                            integer,
                            custom: function(value) {
                                return _.parseInt(value) > 0;
                            }
                        }
                    }
                }
            }
        }
    },
    methods: {
        addPosition() {
            this.form.items.push({
                license_type_id: null,
                quantity: 1
            });
        },
        removePosition(index) {
            this.$delete(this.form.items, index);
        },
        back() {
            return this.$emit('back', 1);
        },
        async save() {
            this.scenario = SCENARIO_SAVE;
            return await this.submit();
        },
        async build() {
            this.scenario = SCENARIO_BUILD;
            return await this.submit();
        },
        async submit() {
            this.waiting[this.scenario] = true;
            this.serverErrors = null;

            if (this.validate()) {
                const action = this.scenario === SCENARIO_BUILD ? 'build' : this.action;
                const { success, error, data } = await this.$store.dispatch(`order/${action}`, {
                    ...this.form
                });

                if(success)
                    this.$emit('success', data);
                else
                    this.serverErrors = error;
            }

            this.waiting[this.scenario] = false;
            return;
        },
        async cancel(confirm) {
            // Cancel 
            if(_.isNil(confirm))
            {
                this.popup.cancel.show = true;
                return ;
            }
            // Confirm cancel popup
            if(confirm === false)
            {
                this.popup.cancel.show = false;
                this.popup.cancel.error = null;
                return ;
            }
            // Cancel handler
            if(confirm === true)
            {
                this.waiting.popup.cancel = true;
                const {success, error} = await this.$store.dispatch('order/cancel', {
                    id: _.get(this.model, 'id', 0)
                });
                if(success)
                    return this.$emit('back', 1);
                else
                    this.popup.cancel.error = error;

                this.waiting.popup.cancel = false;
                return ;
            }

            return ;
        },
        // async remove(confirm) {
        //     // Cancel 
        //     if(_.isNil(confirm))
        //     {
        //         this.popup.remove.show = true;
        //         return ;
        //     }
        //     // Confirm remove popup
        //     if(confirm === false)
        //     {
        //         this.popup.remove.show = false;
        //         this.popup.remove.error = null;
        //         return ;
        //     }
        //     // Remove handler
        //     if(confirm === true)
        //     {
        //         this.waiting.popup.remove = true;
        //         const {success, error} = await this.$store.dispatch('order/remove', {
        //             id: _.get(this.model, 'id', 0)
        //         });
        //         if(success)
        //             return this.$router.push('/order').catch(() => {});
        //         else
        //             this.popup.remove.error = error;

        //         this.waiting.popup.remove = false;
        //         return ;
        //     }

        //     return ;
        // }
    },
    async created() {
        // Fetch partners
        this.partners.loading = true;
        this.partners.items = await this.getDataList('partner', {
            label: 'name',
            value: 'id'
        });
        this.partners.loading = false;

        // Fetch license types
        this.license_types.loading = true;
        this.license_types.items = await this.getDataList('license_type', {
            label: 'name',
            value: 'id'
        });
        this.license_types.loading = false;

        // Update model assign
        if(this.action === 'update')
            _.assign(this.form, this.model);
    }
}
</script>
<style scoped lang="scss">

</style>